import React from "react";
import {Assessment, BrightnessAuto, SupervisedUserCircle, Info, Accessible, Ballot, TrendingUp, AccountBalance, School,Business, Computer, RecordVoiceOver} from "@material-ui/icons";
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import {getRoutes} from "./Route";
import AssignmentIcon from '@material-ui/icons/Assignment';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
const menu = [
    {
        id: 'investor',
        label: 'Investisseur',
        icon: <TrendingUp/>,
        children: [
            {id: 'investor_dashboard_v2', label: 'Tableau de bord'},
        ]
    },
    {
        id: 'reporting_marketing',
        label: 'Reporting marketing',
        icon: <Assessment/>,
        children: [
            {id: 'reporting_marketing_dashboard', label: 'Tableau de bord'},
            {id: 'reporting_marketing_campaign', label: 'Gestion des Campagnes'}
        ]
    },
    {
        id: 'crm',
        label: 'CRM',
        icon: <SupervisedUserCircle/>,
        children: [
            {id: 'crm_reporting', label: 'Reporting commercial'},
            {id: 'crm_dashboard_manager', label: 'Tableau de bord manager'},
            {id: 'crm_dashboard', label: 'Tableau de bord (bêta)'},
            {id: 'crm_prospect_list', label: 'Liste des contacts'},
            {id: 'crm_commercial_task', label: 'Mon agenda'},
            {id: 'crm_hotline', label: 'Mes hotlines'},
            {id: 'crm_relaunch_list', label: 'Suivi des inscriptions'},
            {id: 'crm_personal_relaunch_list', label: 'Suivi des inscriptions personnel'},
            {id: 'crm_commercial_management_registration_list', label: 'Gestion commercial'},
            {id: 'crm_commercial_users_management_list', label: 'Gestion utilisateurs commercial'},
        ]
    },
    {
        id: 'hr',
        label: 'Ressources Humaine',
        icon: <BarChartIcon/>,
        children: [
            {id: 'hr_chart', label: 'Organigramme hiérarchique'},
            {id: 'hr_hierarchy', label: 'Superviseurs - Employé(e)s'},
        ]
    },
    {
        id: 'webcoach',
        label: 'Webcoach',
        icon: <RecordVoiceOver/>,
        children: [
            {id: 'webcoach_relaunch', label: 'Interface de relance'},
            {id: 'webcoach_manager', label: 'Gestion Webcoach'},
        ]
    },
    {
        id: 'reporting_pedagogic',
        label: 'Reporting Pédagogique',
        icon: <AssignmentIcon/>,
        children: [
            {id: 'reporting_pedagogic_survey_satisfaction', label: 'Enquête satisfaction'},
            {id: 'reporting_pedagogic_epp_list', label: 'Évaluation pratiques professionnelles'},
        ]
    },
    {
        id: 'financial_management',
        label: 'Gestion financière',
        icon: <LibraryBooksIcon/>,
        children: [
            {id: 'financial_management_billing_v2_list', label: 'Facturation ANDPC V2'},
            {id: 'financial_management_billing_fifpl_list', label: 'Facturation FIFPL'},
            {id: 'financial_management_billing_cpf_list', label: 'Facturation CPF'},
            {id: 'financial_management_billing_personal', label: 'Facturation PERSONNEL'},
            {id: 'financial_management_payment_follow', label: 'Suivi des règlements'},
            {id: 'financial_management_relaunch_list', label: 'Suivi des relances'},
            {id: 'financial_management_statistics', label: 'Statistiques'},
            {id: 'financial_management_andpc_email_tracking_list', label: 'Suivi des emails Andpc'},
        ]
    },
    {
        id: 'content_manager',
        label: 'Gestion de contenu',
        icon: <Ballot/>,
        children: [
            {id: 'content_manager_formation_list', label: 'Formations'},
            {id: 'content_manager_chapter_list', label: 'Chapitres'},
            {id: 'content_manager_course_list', label: 'Cours'},
            {id: 'content_manager_resource_list', label: 'Ressource'},
            {id: 'content_manager_resource_type_list', label: 'Ressource Type'},
            {id: 'content_manager_resource_group_list', label: 'Ressource Groupe'},
            {id: 'content_manager_virtual_classroom_list', label: 'Classes virtuelles'},
            {id: 'content_manager_virtual_classroom_period_list', label: 'Classes virtuelles Périodes'},

        ]
    },
    {
        id: 'marketing_manager',
        label: 'Gestion marketing',
        icon: <Business/>,
        children: [
            {id: 'marketing_manager_thematic_list', label: 'Thématique'},
            {id: 'marketing_manager_library_resource_list', label: 'Ressource'},
            {id: 'marketing_manager_library_resource_type_list', label: 'Ressource Type'},
            {id: 'marketing_manager_featured_resource', label: 'Ressource à la une'},
            {id: 'marketing_manager_catalog_list', label: 'Gestion du catalogue'},
            {id: 'marketing_manager_hubspot_queue', label: "Liste d'attente Hubspot"},
            {id: 'marketing_manager_stripe_promotion_code', label: "Code promo"}
        ]
    },
    {
        id: 'administrator',
        label: 'Administrateur',
        icon: <BrightnessAuto/>,
        children: [
            {id: 'administrator_user_list', label: 'Utilisateurs'},
            {id: 'administrator_organism_list', label: 'Organismes'},
            {id: 'administrator_pole_list', label: 'Pôles'},
            {id: 'administrator_session_list', label: 'Sessions'},
            {id: 'administrator_role_list', label: 'Rôles'},
            {id: 'administrator_formation_group_property_list', label: 'Propriétés de groupe'},
            {id: 'administrator_user_hotline_type_list', label: 'Hotline Interne Type'},
            {id: 'administrator_prospect_hotline_type_list', label: 'Hotline Externe Type'},
            {id: 'administrator_erp_logs', label: 'Logs des requêtes ERP'},
            {id: 'administrator_token_api', label: 'Gestion token API'},

            {id: 'administrator_finance_type_list', label: 'Types de financement'},
        ]
    },
    {
        id: 'community',
        label: 'Forum',
        icon: <PeopleIcon/>,
        children: [
            {id: 'community_prospect_list', label: 'Membres'},
            {id: 'community_group_private_list', label: 'Groupe privé'},
            {id: 'community_group_formation_list', label: 'Groupe formation'},
        ]
    },
    {
        id: 'support',
        label: 'Support',
        icon: <Accessible/>,
        children: [
            {id: 'support_hotline_dashboard', label: 'Tableau de bord'},
            {id: 'support_user_hotline', label: 'Hotlines interne'},
            {id: 'support_prospect_hotline', label: 'Hotlines externe'},
        ]
    },
    {
        id: 'itInfrastructure',
        label: 'Parc Informatique',
        icon: <Computer />,
        children: [
            {id: 'it_infrastructure_equipment_list', label: 'Liste d\'équipement'},
            {id: 'it_infrastructure_equipment_type_list', label: 'Liste des types d\'équipement'},
            {id: 'it_infrastructure_equipment_brand_list', label: 'Liste des marques d\'équipement'},
            {id: 'it_infrastructure_equipment_model_list', label: 'Liste des modèles d\'équipement'},
        ]
    },
    {
        id: 'former',
        label: 'Formateur',
        icon: <School/>,
        children: [
            {id: 'former_virtual_classroom_list', label: 'Classes virtuelles'},
        ]
    },
    {
        id: 'about',
        label: 'À propos de l\'ERP',
        icon: <Info/>,
        children: [
            {id: 'about_roadmap_si', label: 'Roadmap SI'},
            {id: 'about_roadmap_ped', label: 'Roadmap Pédagogie'}
        ]
    }
];

export const getMenu = () => {
    let routes = getRoutes();
    let menuTmp = [];

    for (let index1 in menu) {
        let children = [];

        for (let index2 in menu[index1].children) {
            if (routes[menu[index1].children[index2].id]) {
                let c = menu[index1].children[index2];
                c.path = routes[menu[index1].children[index2].id].path

                if (c.params) {
                    for (let key in c.params) {
                        if (c.params[key]) {
                            let regEx = new RegExp(':' + key + '[?]?', 'gi');
                            c.path = c.path.replace(regEx, c.params[key]);
                        } else {
                            let regEx = new RegExp('/:' + key + '[?]?', 'gi');
                            c.path = c.path.replace(regEx, '');
                        }
                    }
                }

                children.push(c);
            }
        }

        if (children.length > 0) {
            let m = {
                id: menu[index1].id,
                label: menu[index1].label,
                icon: menu[index1].icon,
            };
            m.children = children;
            menuTmp.push(m);
        }
    }

    return menuTmp;
}
