import React from 'react'
import TableComponent from '../../../../Component/TableComponent'
import Api from '../../../../Api'

export default function RelatedFormations(props) {
  const columns = [
    {
        name: "id",
        label: "id",
        options: {filter: true, sort: true}
    },
    {
        name: "name",
        label: "Formation",
        options: {filter: true, sort: true}
    },
    {
        name: "organismFormation",
        label: "Organisme de formation",
        options: {filter: false, sort: false}
    }
  ];

  React.useEffect(() => {

  }, [props.match.params.id])
  return (
    <TableComponent
      id={'administrator_finance_type_formations_list'}
      title={'Formations liées à ce type de financement'}
      columns={columns}
      promiseData={(resolve) => {
          Api.get({
                  route: 'administrator_finance_type_get_formations',
                  params: { id: props.match.params.id }
              },
              (response) => {                            
                  let data = response.data;
                  resolve(data);
              });
      }}
    />
  )
}
