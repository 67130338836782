import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Edit } from "@material-ui/icons";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { makeStyles } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import SelectComponent from "../../../Component/SelectComponent";
import TitleComponent from "../../../Component/TitleComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { TableReload } from "../../../Action/TableAction";
import { useDispatch } from "react-redux";

function UserHotlineList(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'organism',
            label: 'Organisme',
            options: {filter: true, sort: true}
        },
        {
            name: 'user',
            label: 'Utilisateur',
            options: { filter: true, sort: true }
        },
        {
            name: 'hotlineTypeName',
            label: 'Demande',
            options: { filter: true, sort: true }
        },
        {
            name: 'authorComment',
            label: 'Commentaire',
            options: { filter: true, sort: true }
        },
        {
            name: 'author',
            label: 'Créé par',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAt',
            label: 'Créé le',
            options: { filter: true, sort: true }
        },
        {
            name: 'supportComment',
            label: 'Commentaire du support',
            options: { filter: true, sort: true }
        },
        {
            name: 'treatedAt',
            label: 'Suivi',
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const [formHotline, setFormHotline] = React.useState({
        user: {
            name: 'user',
            label: 'Utilisateur',
            textHelper: 'Saisissez un utilisateur.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Choisissez un organisme.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        userHotlineType: {
            name: 'userHotlineType',
            label: 'Type',
            textHelper: 'Choisissez un type de problème.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        authorComment: {
            name: 'authorComment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {
                max: 1050
            }
        },
    });
    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        isTreated: {
            name: 'isTreated',
            label: 'Hotline traitée',
            textHelper: 'Hotline traitée.',
            type: 'boolean',
            defaultValue: false,
            options: {}
        }
    });
    const [dialogUser, setDialogUser] = React.useState(false);
    const [dialogUserAdd, setDialogUserAdd] = React.useState(false);
    const [infoUser, setInfoUser] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const handlerHotlineInternal = formHandlerInit(formHotline, setFormHotline);
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);
    React.useEffect(handlerHotlineInternal.start, []);

    const saveAdd = () => {
        if (handlerHotlineInternal.checkError()) {
            console.log('Error');
        } else {
            handlerHotlineInternal.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);

            Api.post({
                route: 'support_user_hotline_add',
                data: handlerHotlineInternal.getData()
            }, (response) => {
                handlerHotlineInternal.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 201) {
                    setDialogUserAdd(false)
                    dispatch(
                        SnackbarOpen({
                            text: 'Hotline enregistrée.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handlerHotlineInternal.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(TableReload('support_user_hotline_list'));
            });
        }
    };

    const saveEdit = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);
            let datas = handler.getData();
            Api.post({
                route: 'support_user_hotline_edit',
                data: datas,
                params: { id: infoUser.id }
            },
                (response) => {
                    handler.setFormLoading(false)
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);

                    if (response.status === 200) {
                        setDialogUser(false);
                        dispatch(
                            SnackbarOpen({
                                text: 'Hotline a été traitée.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                        if (response.error.message) {
                            dispatch(SnackbarOpen({ text: response.error.message, variant: 'error' }));
                        }
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(TableReload('support_user_hotline_list'));
                });
        }
    };
    const getAction = (row) => {
        return (
            <div style={{ width: 90 }}>
                <Tooltip title={'Répondre a la Hotline'} placement="left">
                    <IconButton onClick={() => {
                        setDialogUser(true);
                        setInfoUser(row);
                        handler.setValue('comment', row.supportComment);
                        handler.setValue('isTreated', !!row.treatedAt);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };
    const [optionsUserHotlineType, setOptionsUserHotlineType] = React.useState([]);
    const [optionsHotline, setOptionsHotline] = React.useState([]);
    const [optionsOrganism, setOptionsOrganism] = React.useState([]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Hotlines interne',
            context: 'Support',
            description: ''
        });
        Api.get({
            route: 'select_user_hotline_types',
        },
            (response) => {
                if (response && response.data) {
                    setOptionsUserHotlineType(response.data);
                }
            });
        Api.get({
            route: 'select_organisms',
        },
            (response) => {
                if (response && response.data) {
                    setOptionsOrganism(response.data);
                }
            });
        Api.get({
            route: 'select_user_hotline',
        },
            (response) => {
                if (response && response.data) {
                    setOptionsHotline(response.data);
                }
            });
        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'support_user_hotline_list'}
                title={'Hotlines'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une hotline',
                    onClick: () => {
                        setDialogUserAdd(true);
                    }
                }}
                promiseData={(resolve) => {
                    Api.get({
                        route: 'support_user_hotline_list'
                    },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction({ ...data[index] });
                                data[index].createdAt = moment(data[index].createdAt.date).format('ll');
                                data[index].treatedAt = data[index].treatedAt ? moment(data[index].treatedAt.date).format('ll') : 'En cours...';
                                data[index].supportComment = data[index].supportComment ? data[index].supportComment : '-';
                                data[index].organism = data[index].organism ? data[index].organism : '-';
                            }
                            resolve(data);
                        });
                }}
            />

            <br />

            <Dialog open={dialogUserAdd} maxWidth={'xl'} className={classes.DialogBoxHotline} onClose={() => setDialogUserAdd(false)}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>Ajouter une hotline</DialogTitle>
                <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SelectComponent name={'user'} handler={handlerHotlineInternal} options={optionsHotline} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SelectComponent name={'organism'} handler={handlerHotlineInternal} options={optionsOrganism} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SelectComponent name={'userHotlineType'} handler={handlerHotlineInternal} options={optionsUserHotlineType} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'authorComment'} handler={handlerHotlineInternal} multiline={true} nullable={true} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogUserAdd(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={saveAdd} disabled={loading} />
                </DialogActions>
            </Dialog>

            <br />

            <Dialog open={dialogUser} maxWidth={'lg'} className={classes.DialogBox} onClose={() => setDialogUser(false)}>
                <p style={{ fontSize: '1.25rem', color: '#5E6E82', margin: 0, padding: '16px 24px 0px 24px', fontWeight: 'bold' }}>Hotline</p>
                <p style={{ fontSize: 13, color: '#5E6E82', margin: 0, padding: '2px 24px 15px 24px' }} ><b>Crée par</b> {infoUser.author}</p>
                <div className={classes.Box}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TitleComponent title={'Details de l\'utilisateur'} />
                            <Grid container spacing={0} className={classes.GridBox}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <p><span>Nom Prénom :</span> {infoUser.user}</p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <p><span>Mail :</span> {infoUser.email}</p>
                                </Grid>
                            </Grid>
                            <p style={{ padding: '6px 0' }}><span>Demande :</span> {infoUser.hotlineTypeName}</p>
                            <span>Problème :</span>
                            <p className={classes.Comment}>{infoUser.authorComment ? infoUser.authorComment : '-'}</p>
                            <span>Répondre au problème :</span>
                            <DialogContent>
                                <TextFieldComponent name={'comment'} handler={handler} multiline={true} />
                                <CheckboxComponent name={'isTreated'} handler={handler} direction={'right'} />
                            </DialogContent>
                        </Grid>
                    </Grid>
                </div>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogUser(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={saveEdit} disabled={loading} />
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    Box: {
        padding: '0 37px 20px 37px',
        color: '#5E6E82',
        '& span': {
            fontWeight: 'bold',
        },
        '& p': {
            margin: '0px',
        },
    },
    Comment: {
        background: 'rgba(94, 110, 130, 0.06)',
        padding: '4px 16px',
        border: '1px solid rgba(94, 110, 130, 0.25)',
        borderRadius: '3px',
        margin: '5px !important',
    },
    Materials: {
        padding: '4px 16px',
        margin: '5px !important',
    },
    MaterialsButton: {
        background: 'rgba(94, 110, 130, 0.06)',
        padding: '9px 25px',
        border: '1px solid rgba(94, 110, 130, 0.25)',
        margin: '5px !important',
        borderRadius: '3px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        lineHeight: '1.75',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        fontSize: '13px',
        color: '#5E6E82',
        cursor: 'pointer',
        '&:hover': {
            background: 'rgba(94, 110, 130, 0.16)',
        },
    },
    Commentaire: {
        background: 'rgba(94, 110, 130, 0.06)',
        padding: '4px 16px',
        border: '1px solid rgba(94, 110, 130, 0.25)',
        borderRadius: '3px',
        margin: '5px !important',
    },
    DialogBox: {
        '& .MuiDialog-paper': {
            width: '600px',
        },
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    }
});
export default UserHotlineList;
