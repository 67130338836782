import React from 'react'
import ContentViewComponent from '../../../Component/ContentViewComponent'
import TableComponent from '../../../Component/TableComponent'
import Api from '../../../Api'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import {Edit, Delete} from "@material-ui/icons";
import { getRoutePathname } from '../../../Config/Route'
import { makeStyles } from '@material-ui/core'

import { useDispatch } from "react-redux";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { LinearProgressMainLayoutActivate } from "../../../Action/LinearProgressMainLayoutAction";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import {TableReload} from "../../../Action/TableAction";



export default function FinanceTypeList(props) {

    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const classes = useStyles();

    const columns = [
        {
            name: "id",
            label: "id",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Type",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    function getAction (row) {
        return (
            <div>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => editAction(row)}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        console.log("row", row);
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    function editAction(row){
        props.history.push(getRoutePathname('administrator_finance_type', {id: row.id}))
    }

    function deleteAction(row){
        Api.delete({
            route: 'administrator_finance_type_delete',
            params: {id: row.id}
        }, (response) => {
            console.log("response delete", row.id)
            if (response.status === 200) {
                //reload here                 
                dispatch(TableReload('administrator_finance_type_list'))
            }
            else if (response.status === 404) {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                    })
                );
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        })
    }

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des financements',
            context: 'Administrateur',
            //description: 'Je suis une description'
        });
        setReadyContent(true);
    }, []);
    
    
  return (
    <>
        <div className={classes.alignActionCol}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <TableComponent
                    id={'administrator_finance_type_list'}
                    title={'financetypes'}
                    columns={columns}
                    actionFirst={{
                        label: 'Ajouter un type de financement',
                        onClick: () => props.history.push(getRoutePathname('administrator_finance_type', {id: null}))
                    }}//
                    promiseData={(resolve) => {
                        Api.get({
                                route: 'administrator_finance_type_list'
                            },
                            (response) => {                            
                                let data = response.data;
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            });
                    }}
                />
            </ContentViewComponent>
        </div>
        <SweetAlert
            show={showConfirm}
            title={'Supprimer'}
            text={'Êtes-vous sur de vouloir supprimer ce type de financement :' + actionRow.name + '?'}
            type={'warning'}
            cancelButtonText={'Non'}
            confirmButtonText={'Oui'}
            showCancelButton={true}
            onConfirm={() => {
                dispatch(LinearProgressMainLayoutActivate());
                setShowConfirm(false);
                deleteAction(actionRow);
            }}
            onCancel={() => setShowConfirm(false)}
        />
    </>
  )
}

const useStyles = makeStyles({
    alignActionCol:
    {"& thead > tr > th:last-child " : {
        textAlign: 'right',
    },
    "& tbody > tr > td:last-child " : {
        display: 'flex',
        justifyContent: 'flex-end',
    }
    }
});
